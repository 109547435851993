<template>
  <div>
    <section class="hero">
      <div class="border-box" />
      <div class="hero-text container">
        <div class="line1">
          Our services
        </div>
        <div class="line2">
          我們的服務<br>
        </div>
      </div>
    </section>
    <section class="intro-box">
      <div class="left-text">
        <div class="cn-intro">
          在線旅遊
        </div>
        <div class="en-intro">
          Online travel
        </div>
        <div class="services-box">
          <div class="service_title">
            旅行定制
          </div>
          <section class="service_category">
            <div class="service-item">
              <img
                class="service_images"
                src="../assets/trip.png"
                alt=""
              ><div>
                精選行程<br>
                深度體驗
              </div>
            </div>
            <div class="service-item">
              <img
                class="service_images"
                src="../assets/consulting.png"
                alt=""
              ><div>
                專業顧問<br>
                7*24小時服務保障
              </div>
            </div>
            <div class="service-item">              
              <img
                class="service_images"
                src="../assets/one_stop.png"
                alt=""
              ><div>
                一站式服務
              </div>
            </div>
          </section>
          <div class="service_title">
            熱門目的地
          </div>
          <section class="service_category_row">
            <div class="service-destination">
              <div class="service-item-title">
                國内
              </div> 北京 |上海 | 廣州 | 深圳 | 香港 | 澳門 更多>>
            </div>
            <div class="service-destination">
              <div class="service-item-title">
                國外
              </div> 美國 | 英國 | 德國 | 法國 | 意大利 | 西班牙 | 葡萄牙 更多>>
            </div>
          </section>
          <div class="service_title">
            精選酒店
          </div>
          <section class="service_category">
            <div class="hotel-item">
              北京嘉里大酒店
              <img
                src="../assets/kerry_hotel_bj.png"
                alt=""
              >
            </div>
            <div class="hotel-item">
              上海外灘華爾道夫
              <img
                src="../assets/Waldorf_Astoria_Shanghai.png"
                alt=""
              >
            </div>
            <div class="hotel-item">
              澳門葡京酒店
              <img
                src="../assets/Lisboa_Macau.png"
                alt=""
              >
            </div>
          </section>
          <div class="service_title">
            游學
          </div>
          <section class="service_category">
            <div>Hong Kong 香港</div>
            <div>Britain 英國</div>
            <div>Germany 德國</div>
            <div>Spain 西班牙</div>
            <div>America 美國</div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// the main area is below the menu bar
export default {
  name: 'AmisService',
}
</script>

<style lang="scss" scoped>
  .hero {
  background-image: url("../assets/bg_our_services.png");
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
  z-index: 1;
  display: flex;
  .border-box {
    position:absolute;
    left: 0%;
    top: 0%;
    border: 5px;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: #F3F8F9;
    opacity: 0.49;
    flex: 1;
    background: #F3F8F9;
    opacity: 0.49;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    z-index: 25;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content:center;
    vertical-align: center;


    .line1{
      width: 80%;
      font-size: calc(3.2rem + 3.2vw);
      font-family: "SourceHanSansCN-Bold";
      font-weight: bold;
      letter-spacing: 0.1em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
    }
    .line2 {
      width: 80%;
      font-size: calc(1.6rem + 1.6vw);
      font-family: "MyriadPro-Regular";
      font-weight: 400;
      color: #353535;
      justify-content: center;
    }
  }
}
.intro-box {
    position:relative;
    left: 20%;
    width: 80%;
    z-index: 25;
    background: #F3F8F9;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .left-text{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .cn-intro {
      padding-top: 25px;
      font-size: 4em;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #353536;
    }
    .en-intro {
      font-size: 4em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
      line-height: 120px;
    }
    .services-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .service_title {
      width: 20%;
      text-align: center;
      font-size: 2em;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #353536;
      line-height: 57px;
      background-color: #89B1B9;
      border-radius: 0.5em;
    }
    .service_category{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 60%;
      padding-bottom: 4em;
    }
    .service_category_row{
      display: flex;
      flex-direction: column;
      padding-bottom: 4em;
    }
    .service-destination{
      width: 100%;
    }
    .service-item{
      width: 30%;
    }
    .service_images{
      width: 50%;
    }
    .service-item-title{
      display: inline;
      font-size: 2.5em;
    }
    .hotel-item {
      width: 30%;
    }
  }

</style>
