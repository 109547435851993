<template>
  <div class="footer">
    <div class="amis-qrcode">
      <img src="../assets/qr_about_risk.jpg">
    </div>
    <div class="center-social-icons">
      <img
        class="social-icon"
        src="../assets/wechat_logo.png"
      >
      <img
        class="social-icon"
        src="../assets/weibo_logo.png"
      >
      <img
        class="social-icon"
        src="../assets/facebook_logo.png"
      >
      <img
        class="social-icon"
        src="../assets/linkedin_logo.png"
      >
    </div>
    <div>
      <a href="mailto:bd@amis.hk">
        <img  
          class="email-icon"
          src="../assets/email_icon.png" 
        > 
        <div>BD@amis.hk</div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
    name: 'AmisLogo',
}
</script>
<style lang="scss" scoped>
.footer{
    display: flex;
    height: 196px;
    background: #89B1B9;
    align-items: center;
    flex-flow: row wrap;
    column-gap: 10em;
}
.amis-qrcode {
    flex: 3 0 200px;
    margin-left: 20px;
}
.amis-qrcode img {
  width: 100px;
  height: 100px;
}
.center-social-icons{
    flex: 3 0 200px;
    display: flex;
    flex-flow: row wrap;
    column-gap: 2em;
    .social-icon {
        flex: 0 0 2em;
        height: 61px;
        width: 61px;
        border-radius: 3em;
    }
}

.email-icon{
    flex: 3 0 200px;
    margin-left: auto;
    border-radius: 50%;
}
</style>
