<template>
  <div>
    <section class="hero">
      <div class="border-box" />

      <div class="hero-text container">
        <div class="line1">
          About Us
        </div>
        <div class="line2">
          關於我們<br>
        </div>
      </div>
    </section>
    <section class="intro-box">
      <div class="left-text">
        <div class="cn-intro">
          公司介紹
        </div>
        <div class="en-intro">
          Company <br>
          Introduction
        </div>
        <!-- <div class="left-line">
          &nbsp;
        </div> -->
        <div class="description">
          友誼商業服務香港有限公司（AMIS）是一家極具創新性的公司，
          為全球旅客提供高質量的旅行及商業服務，也為酒店業提供市場及技術解決方案。
          <br>
          AMIS團隊成員擁有超過十年的在線旅遊行業經驗，並且在品牌構建、市場運營、
          信息技術等方面極具創新與創造力。
          <br>
          公司服務於大型金融機構與企業，為其提供
          一站式服務方案。
        </div>
      </div>
      <div class="right-images">
        <div class="image-color-block">
          &nbsp;
        </div>
        <img
          src="../assets/right_image.png"
          alt="amis logo"
        >
        <div class="image-color-block2">
          &nbsp;
        </div>
      </div>
      
      <div class="partner">
        <img
          class="partners"
          src="../assets/partners.png"
        >
      </div>
    </section>
  </div>
</template>

<script>
// the main area is below the menu bar
export default {
  name: 'AmisHome',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hero {
  background-image: url("../assets/bg_building.png");
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
  z-index: 1;
  display: flex;
  .border-box {
    position:absolute;
    left: 0%;
    top: 0%;
    border: 5px;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: #F3F8F9;
    opacity: 0.49;
    flex: 1;
    background: #F3F8F9;
    opacity: 0.49;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    z-index: 25;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content:center;
    vertical-align: center;


    .line1{
      width: 80%;
      font-size: calc(3.2rem + 3.2vw);
      font-family: "SourceHanSansCN-Bold";
      font-weight: bold;
      letter-spacing: 0.1em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
    }
    .line2 {
      width: 80%;
      font-size: calc(1.6rem + 1.6vw);
      font-family: "MyriadPro-Regular";
      font-weight: 400;
      color: #353535;
      justify-content: center;
    }
  }
}
.intro-box {
    position:relative;
    left: 20%;
    width: 80%;
    z-index: 25;
    background: #F3F8F9;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .left-text{
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    .cn-intro {
      padding-top: 25px;
      font-size: 4em;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #353536;
    }
    .en-intro {
      font-size: 4em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
      line-height: 120px;
    }
    .left-line {
      border-bottom: 2px solid #89B1B9;
      width: 45%;
      transform: rotate(-45deg);
      transform-origin: right;
      width: calc(200px*1.41);
      height: 200px;
    }
    .description {
      width: 100%;
      font-size: 2em;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #353536;
      line-height: 57px;
    }
    .right-images {
      width: 20%;
      border: 0px;
      padding: 10px;

      .image-color-block {
        background: #D8D9DD;
        float: right;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: 0;
        }
        .image-color-block2 {
        background: #89B1B9;
        margin-left: 0;
        }
      }
      .partners {
      width: 80%;
      padding: 10px 20px 10px 20px;
      position: relative;
      align-self: flex-end;
      border: 1px solid #292727;
      border-radius: 15px;
    }
    .partners:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 5px;
      border: 2px solid #49c553;
    }
  }

</style>
