<template>
  <div class="home">
    <section class="hero">
      <div class="hero-text container">
        <h1>News</h1>
        <hr>
      </div>
    </section>
  </div>
</template>

<script>
// the main area is below the menu bar
export default {
  name: 'AmisNews',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hero {
  background-image: url("../assets/bg_landscape.png");
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
  
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

  }
}

</style>
