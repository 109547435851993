<template>
  <div>
    <section class="hero">
      <div class="border-box" />

      <div class="hero-text container">
        <div class="line1">
          Contact Us
        </div>
        <div class="line2">
          聯繫我們<br>
        </div>
      </div>
    </section>
    <section class="contact-box">
      <div class="cn-contact">
        郵箱：BD@amis.hk
      </div>
      <div class="en-contact">
        地址：香港觀塘開源道 49 號創貿廣場 16 樓 1608室
      </div>
      <div class="map">
        <img
          class="map"
          src="../assets/map.png"
        >
      </div>
    </section>
  </div>
</template>

<script>
// the main area is below the menu bar
export default {
  name: 'AmisContact',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .hero {
  background-image: url("../assets/bg_contact.png");
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
  z-index: 1;
  display: flex;
  .border-box {
    position:absolute;
    left: 0%;
    top: 0%;
    border: 5px;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: #F3F8F9;
    opacity: 0.49;
    flex: 1;
    background: #F3F8F9;
    opacity: 0.49;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    z-index: 25;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content:center;
    vertical-align: center;


    .line1{
      width: 80%;
      font-size: calc(3.2rem + 3.2vw);
      font-family: "SourceHanSansCN-Bold";
      font-weight: bold;
      letter-spacing: 0.1em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
    }
    .line2 {
      width: 80%;
      font-size: calc(1.6rem + 1.6vw);
      font-family: "MyriadPro-Regular";
      font-weight: 400;
      color: #353535;
      justify-content: center;
    }
  }
}

.contact-box {
    position:relative;
    left: 20%;
    width: 80%;
    z-index: 25;
    background: #F3F8F9;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .cn-contact {
      padding-top: 25px;
      font-size: 2em;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #353536;
    }
    .en-contact {
      font-size: 2em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
      line-height: 120px;
    }
      .map {
      width: 80%;
      padding: 10px 20px 10px 20px;
      position: relative;
      align-self: flex-end;
      border: 1px solid #292727;
      border-radius: 15px;
    }
    .map:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 5px;
      border: 2px solid #49c553;
    }
  }

</style>
