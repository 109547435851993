<template>
  <header :class="{'scrolled': scrolledNav}">
    <nav>
      <div class="branding">
        <img src="@/assets/logo.png">
      </div>
      <ul
        v-show="!mobile"
        class="navigation"
      >
        <li>
          <router-link
            class="link"
            :to="{ name: 'Home'}"
          >
            首页
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: 'About'}"
          >
            關於我們
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: 'Service'}"
          >
            我們的服務
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: 'News'}"
          >
            新聞報導
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: 'Contact'}"
          >
            聯繫我們
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: ''}"
          >
            <div class="icon">
              <i class="fa-solid fa-magnifying-glass" />
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: 'Login'}"
          >
            登錄
          </router-link>
        </li>
      </ul>
      <div class="icon">
        <i
          v-show="mobile"
          class="fa fa-bars"
          :class="{ 'icon-active': mobileNav }"
          @click="toggleMobileNav"
        />
      </div>
      <Transition name="mobile-nav">
        <ul
          v-show="mobileNav"
          class="dropdown-nav"
        >
          <li>
            <router-link
              class="link"
              :to="{ name: 'Home'}"
            >
              首页
            </router-link>
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'About'}"
            >
              關於我們
            </router-link>
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'Service'}"
            >
              我們的服務
            </router-link>
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'News'}"
            >
              新聞報導
            </router-link>
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'Contact'}"
            >
              聯繫我們
            </router-link>
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: ''}"
            >
              <a
                href="javascript:void(0);"
                class="icon"
                onclick="myFunction()"
              >
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
              </a>
            </router-link>
          </li>
          <li>
            <router-link
              class="link"
              :to="{ name: 'Login'}"
            >
              登錄
            </router-link>
          </li>
        </ul>
      </Transition>
    </nav> 
  </header>
</template>
<script>

export default {
    name: 'MenuBar',
    data() {
        return {
            scrolledNav: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null,
        }
    },
    created() {
      window.addEventListener("resize", this.checkScreen)
      this.checkScreen()
    },
    methods: {
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav
        },

        updateScroll() {
            const scrollPosition = window.scrollY
            if (scrollPosition > 50) {
                this.scrolledNav = true
            }
            this.scrolledNav = false
        },
        checkScreen() {
            this.windowWidth = window.innerWidth
            if (this.windowWidth<750) {
                this.mobile = true
                return
            }
            this.mobile = false
            this.mobileNav = false
            return
        }
    }
}
</script>

<style lang="scss" scoped>
    header{
        z-index: 99;
        width: 100%;
        position: absolute;
        Transition: .2s ease all;
        color: #fff;
    }
    nav {
        display: flex;
        flex-direction: row;
        padding: 12px 0;
        transition: .2s ease all;
        width: 90%;
        margin: 0 auto;
        @media (min-width: 1140px) {
            max-width: 1140px;
        }
    }
    ul, .link{
      color: #222;
      list-style: none;
      text-decoration: none;
      font-weight: 400;
      color: #353536;
      line-height: 20px;
    }
    li {
        text-transform: uppercase;
        margin-left: 16px;
    }
    .navigation li{
      padding: 0.2vw;
    }
    .navigation .link{    
        font-size: calc(0.6vw + 0.5rem);
        transition: .1s ease all;
        padding-bottom: 4px;
        border-bottom: 1px solid transparent;

        &:hover {
            color: #00afea;
            border-color: #00afea;
        }
    }
    .branding {
        display: flex;
        align-items: center;
        img {
            width: 230px;
            height: 102px;
            transition: .2s ease all;
        }
    }

    .navigation{
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;

    }
    .icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 24px;
        height: 100%;

        i{
            cursor: pointer;
            font-size: 24px;
            transition: 0.4s ease all;
        }
    }

    .icon-active {
        transform: rotate(180deg);
    }
    .dropdown-nav {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        max-width: 250px;
        background-color: #fff;
        top: 0;
        left: 0;

        li {
            margin-left: 0;
            .link {
                color: #000;
            }
        }
    }
    .dropdown-nav .link {
      color: #222;
        list-style: none;
        text-decoration: none;
        font-weight: 400;
        color:  #353536;
        line-height: 20px;        

    }
    .dropdown-nav li{
      padding: 2vw;
    }
</style>