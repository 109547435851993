<template>
  <div>
    <MenuBar />
    <router-view />
    <AmisFooter />
  </div>
</template>

<script>
import MenuBar from './components/MenuBar.vue'
import AmisFooter from './components/AmisFooter.vue'

export default {
  name: 'App',
  components: {
    MenuBar,
    AmisFooter,
  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
.app {
  min-height: 100vh;
  position: relative;
  background-color: #333;
}
.container {
  padding: 0 20px;
  max-width: 1140px;
  margin: 0 auto;
}
</style>