<template>
  <div>
    <section class="hero">
      <div class="border-box" />

      <div class="hero-text container">
        <div class="line1">
          Under Construction
        </div>
        <div class="line2">
          網站建設中<br>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// the main area is below the menu bar
export default {
  name: 'AmisLogin',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .hero {
  background-image: url("../assets/under-construction.jpg");
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
  z-index: 1;
  display: flex;
  .border-box {
    position:absolute;
    left: 0%;
    top: 0%;
    border: 5px;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: #F3F8F9;
    opacity: 0.49;
    flex: 1;
    background: #F3F8F9;
    opacity: 0.49;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    z-index: 25;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content:center;
    vertical-align: center;


    .line1{
      width: 80%;
      font-size: calc(3.2rem + 3.2vw);
      font-family: "SourceHanSansCN-Bold";
      font-weight: bold;
      letter-spacing: 0.1em;
      font-family: Impact;
      font-weight: 400;
      color: #89B1B9;
    }
    .line2 {
      width: 80%;
      font-size: calc(1.6rem + 1.6vw);
      font-family: "MyriadPro-Regular";
      font-weight: 400;
      color: #353535;
      justify-content: center;
    }
  }
}


</style>
